import axios from "axios";

// 创建axios实例
const instanceWithAuth = axios.create({
  baseURL: "/api",
  // 跨域请求时发送Cookie
  withCredentials: true, // 视情况而定
  origin: true,
  // timeout: 5000,
  headers: {
    // 设置后端需要的传参类型
    "content-type": "application/json; charset=utf-8",
    Authorization: "Bearer " + localStorage.getItem("token"),
    // Authorization:
    //   "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhZG1pbiIsImNyZWF0ZWQiOjE3MjkwNzUxMjM5NDAsImV4cCI6MTcyOTY3OTkyM30.QVgje6nnQlKbJ0hS6t19EjkOlP6Q6ui7ePxMtoNlgsHe0xyMedcR-rgOcuU28WVZgxg66SoO_Yst5MYb4c77_g",
  },
});

const instance = axios.create({
  baseURL: "/api",
  // 跨域请求时发送Cookie
  withCredentials: true, // 视情况而定
  origin: true,
  // timeout: 5000,
});
export { instanceWithAuth, instance };
