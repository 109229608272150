<template>
  <div id="app">
    <el-container v-if="$route.path !== '/login'" style="height: 100%; border: 1px solid #eee">
      <el-aside width="200px" style="background-color: rgb(238, 241, 246); height: 100%">
        <Aside></Aside>
      </el-aside>

      <el-container style="height: 100%">
        <el-header style="text-align: right; font-size: 12px">
          <Header></Header>
        </el-header>
        <el-main style="height: 100%">
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>

    <!-- 登录页面只显示主内容区域，不显示Aside和Header -->
    <el-main v-else style="height: 100%">
      <router-view></router-view>
    </el-main>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {
    Aside: () => import("@/components/common/Aside.vue"),
    Header: () => import("@/components/common/Header.vue"),
  },

};
</script>

<style>
#app {
  width: 100%;
  height: 100%;
}
</style>
