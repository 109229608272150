import Vue from "vue";
import App from "./App.vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "./assets/css/global.css"; // 全局样式
import { instanceWithAuth, instance } from "@/axios/index.js";
import router from "@/router/index.js"; // 引入路由

Vue.config.productionTip = false;
Vue.prototype.$axios = instanceWithAuth; // 带有token的axios
Vue.prototype.$axiosAuth = instance; // 不带有token的axios
Vue.use(ElementUI);

new Vue({
  render: (h) => h(App),
  router, // 将路由挂载到vue实例上
}).$mount("#app");
