import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
  // mode: "history", //去掉url中的#
  routes: [
    {
      path: "/",
      name: "index",
      redirect: "/index",
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/components/Login.vue"),
    },
    {
      path: "/index",
      name: "index",
      component: () => import("@/components/Index.vue"),
    },
    {
      path: "/student",
      name: "student",
      component: () => import("@/components/student/StudentInfo.vue"),
    },
    {
      path: "/class",
      name: "class",
      component: () => import("@/components/class/ClassInfo.vue"),
    },
    {
      path: "/position",
      name: "position",
      component: () => import("@/components/position/Position.vue"),
    },
    {
      path: "/studentLeave",
      name: "studentLeave",
      component: () => import("@/components/leave/StudentLeave.vue"),
    },
    {
      path: "/approval",
      name: "approval",
      component: () => import("@/components/leave/Approval/Approval.vue"),
    },
    {
      path: "/device",
      name: "device",
      component: () => import("@/components/access/Access.vue"),
    },
    {
      path: "/access",
      name: "access",
      component: () => import("@/components/access/gateRules/GateRules.vue"),
    },
    {
      path: "/faceGateFlow",
      name: "faceGateFlow",
      component: () => import("@/components/access/faceGateFlow/FaceGateFlow.vue"),
    },
    {
      path: "/gateway",
      name: "gateway",
      component: () => import("@/components/hydropower/gateway/Gateway.vue"),
    },
    {
      path: "/hydropowerDevice",
      name: "hydropowerDevice",
      component: () => import("@/components/hydropower/equipment/HydropowerDevice.vue"),
    },
    {
      path: "/waterRecord",
      name: "waterRecord",
      component: () => import("@/components/record/WaterRecord.vue"),
    },
    {
      path: "/user",
      name: "user",
      component: () => import("@/components/system/User.vue"),
    },
    {
      path: "/role",
      name: "role",
      component: () => import("@/components/system/Role.vue"),
    },
    {
      path: "/menu",
      name: "menu",
      component: () => import("@/components/system/Menu.vue"),
    },
  ],
});

export default router;
